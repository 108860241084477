import Link from "next/link";
import PropTypes from "prop-types";
import safeDataLayerPush from "lib/safeDataLayerPush";
const SubNavItem = ({
  url,
  text,
  parentText
}) => {
  const handleNavItemClick = () => {
    document.activeElement?.blur();
    const menu_category_1 = parentText;
    const menu_category_2 = text;
    safeDataLayerPush({
      event: "menu_click",
      menu_category_1,
      menu_category_2,
      menu_item_location: "header"
    });
  };
  return <li className="nav-main__secondary-item" data-sentry-component="SubNavItem" data-sentry-source-file="index.jsx">
			<Link prefetch={false} onClick={handleNavItemClick} className="nav-main__link nav-main__link-sub" href={url} data-sentry-element="Link" data-sentry-source-file="index.jsx">
				{text}
			</Link>
		</li>;
};
SubNavItem.propTypes = {
  parentText: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string
};
export default SubNavItem;