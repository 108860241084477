"use client";

import PropTypes from "prop-types";
import { useState } from "react";
import styles from "./email-signup.module.scss";
import * as Sentry from '@sentry/nextjs';
import safeDataLayerPush from "lib/safeDataLayerPush";
import ChevronR from "assets/icons/chevron-r.svg";

//This is a little too specific for a generic helpers file IMO.
export const emailSignupApiCall = async (emailAddress, source) => {
  const endpoint = "/api/navigation/email-signup";
  let pageId = '';
  if (window) {
    pageId = window.location.url;
  }

  //Build form datat object to post to server
  const dataObj = JSON.stringify({
    emailAddress: emailAddress,
    pageInfo: source,
    currentPageId: pageId
  });
  try {
    //Post request
    const data = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Accept": "application/json, text/javascript, */*; q=0.01",
        "Content-Type": "application/json"
      },
      body: dataObj
    });
    const res = await data.json();
    return {
      success: res.success,
      message: res.message
    };
  } catch (error) {
    Sentry.captureException(error);
    return {
      success: false,
      message: error.message
    };
  }
};
const EmailSignup = props => {
  const {
    header,
    cta
  } = props;
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const pageInfo = "web_home";
  const submit = async evt => {
    evt.preventDefault();
    //Hide Previous Error State
    if (isValid && email != "") {
      setShowError(false);
      setShowSuccess(false);
      const res = await emailSignupApiCall(email, pageInfo);
      updatePageAfterSubmit();
      if (res.success) {
        setShowSuccess(true);
        setSuccessMessage(res.message);
      } else {
        setShowError(true);
        setErrorMessage(res.message);
      }
    }
  };
  const checkValidity = event => {
    const emailInput = event.target;
    setIsValid(emailInput.validity.valid);
    setIsEmailDirty(true);
  };
  const updatePageAfterSubmit = () => {
    safeDataLayerPush({
      "Category": "Email",
      "Action": "Sign up",
      "event": "HomeNewsletterFormSubmitted"
    });
  };
  return <section className={styles["homepage-email"]} data-sentry-component="EmailSignup" data-sentry-source-file="index.jsx">
			<form name="footerNewsletter">
				<div className={styles.wrapper}>
					<label className={styles.label} htmlFor="email">
						<h1 className={styles.title}>{header}</h1>
					</label>
					<div className={styles["form-fields"]}>
						{!showSuccess ? <div className={styles["input-wrapper"]}>
							<input className="input" type="email" autoComplete="email" id="email" placeholder={cta} value={email} onChange={e => {
              setEmail(e.target.value);
            }} onBlur={checkValidity} required="" />
							<button aria-label="NewsLetter Signup" name="Newsletter Signup" className={styles.submit} type="submit" onClick={submit}>
								<ChevronR />
							</button>
						</div> : null}
						{!isValid && isEmailDirty ? <div className="newsletter-cta__error notification fill-warn">
							<span className="newsletter-cta__banner-text">Please enter a valid email address.</span>
							<span className="newsletter-cta__close"></span>
						</div> : null}
						{showError ? <div className="newsletter-cta__error notification fill-warn">
							<span className="newsletter-cta__banner-text">{errorMessage} </span>
							<span className="newsletter-cta__close" onClick={() => {
              setShowError(false);
            }}></span>
						</div> : null}
						{showSuccess ? <div className="newsletter-cta__confirm notification fill-success">
							<span className="newsletter-cta__banner-text">{successMessage}</span>
							<span className="newsletter-cta__close" onClick={() => {
              setShowSuccess(false);
            }}></span>
						</div> : null}
					</div>
				</div>
			</form>
		</section>;
};
EmailSignup.propTypes = {
  cta: PropTypes.any,
  header: PropTypes.any
};
export default EmailSignup;