import PropTypes from "prop-types";
import Link from "next/link";
import SubNavItem from "components/global/global-navigation/sub-nav-item";
import ExternalIcon from "assets/icons/link-external.svg";
const NavItem = ({
  text,
  url,
  subItems,
  external,
  handleClick,
  isActive
}) => {
  const baseLinkClassString = `nav-main__link nav-main__link--primary`;
  const isExternalLinkClass = external ? "nav-main__link--external" : "nav-main__link--parent";
  const isActiveLiClass = isActive ? "is-active" : "";
  if (url) {
    return <li className={`nav-main__primary-item ${isActiveLiClass}`}>
				<Link prefetch={false} className={`${baseLinkClassString} ${isExternalLinkClass}`} href={url} data-nav-id={text}>
					{text}
					<span className="header__toggle-indicator" />
					{external && <span className="nav-main__external-icon">
							<ExternalIcon />
						</span>}
				</Link>
			</li>;
  }
  return <li className={`nav-main__primary-item ${isActiveLiClass}`} data-sentry-component="NavItem" data-sentry-source-file="index.jsx">
			<a href="#" className={`${baseLinkClassString} ${isExternalLinkClass}`} onClick={handleClick} data-nav-id={text}>
				{text}
				<span className="header__toggle-indicator" />
			</a>
			{!!subItems?.length && <div className="nav-main__subnav">
					<ul className="nav-main__secondary">
						{subItems.map(subItem => <SubNavItem parentText={text} key={subItem.text} url={subItem.url} text={subItem.text} />)}
					</ul>
				</div>}
		</li>;
};
NavItem.propTypes = {
  external: PropTypes.bool,
  handleClick: PropTypes.func,
  isActive: PropTypes.bool,
  subItems: PropTypes.array,
  text: PropTypes.string,
  url: PropTypes.string
};
export default NavItem;