"use client";

import React from "react";
import PropTypes from "prop-types";
import styles from "./tabs.module.scss";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import ScreenReaderOnly from "components/accesibility/screen-reader-only";
const Tabs = ({
  smallTabs,
  handleTabChange,
  tabNames,
  activeTab,
  inputGroupName,
  screenreaderLegendText,
  orientation = "horizontal"
}) => {
  const ref = useRef();
  const {
    events
  } = useDraggable(ref);
  const isChecked = category => category.toLowerCase() === activeTab.toLowerCase();
  const orientationClass = orientation === "vertical" ? styles.vertical : styles.horizontal;
  return <div className={`${styles.wrapper} ${orientationClass}`} {...events} ref={ref} data-sentry-component="Tabs" data-sentry-source-file="index.jsx">
			<fieldset className={styles.fieldset}>
				<ScreenReaderOnly Element={"legend"} text={screenreaderLegendText} data-sentry-element="ScreenReaderOnly" data-sentry-source-file="index.jsx" />
				{tabNames.map(tabName => {
        return <div className={styles.tab} key={tabName}>
							<input type="radio" id={`${tabName}-tab-id`} name={inputGroupName} value={tabName} className={styles.input} checked={isChecked(tabName)} onChange={handleTabChange} />
							<label htmlFor={`${tabName}-tab-id`} className={`${styles.label} ${smallTabs ? styles.small : ""}`}>
								<div className={styles.tabText}>{tabName}</div>
							</label>
						</div>;
      })}
			</fieldset>
		</div>;
};
Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  inputGroupName: PropTypes.string.isRequired,
  screenreaderLegendText: PropTypes.string.isRequired,
  smallTabs: PropTypes.bool,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};
export default Tabs;