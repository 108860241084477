import PropTypes from "prop-types";
import styles from "./screen-reader-only.module.scss";
const ScreenReaderOnly = ({
  Element = "span",
  text,
  ...restProps
}) => {
  return <Element {...restProps} className={styles.text} data-sentry-element="Element" data-sentry-component="ScreenReaderOnly" data-sentry-source-file="index.jsx">{text}</Element>;
};
ScreenReaderOnly.propTypes = {
  text: PropTypes.any,
  Element: PropTypes.string
};
export default ScreenReaderOnly;