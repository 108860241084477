const visit = {
	text: "Visit",
	subItems: [
		{
			url: "/plan-your-visit",
			text: "Plan Your Visit"
		},
		{
			url: "https://engage.metmuseum.org/admission",
			text: "Buy Tickets"
		},
		{
			url: "https://engage.metmuseum.org/members/membership/?promocode=56373",
			text: "Become a Member"
		},
		{
			url: "/tours",
			text: "Free Tours"
		},
		{
			url: "https://maps.metmuseum.org/",
			text: "Museum Map"
		},
		{
			url: "/plan-your-visit/dining",
			text: "Food and Drink"
		},
		{
			url: "/learn/accessibility",
			text: "Accessibility"
		},
		{
			url: "/plan-your-visit/group-visits",
			text: "Group Visits"
		}

	]
}

const exhibitions = {
	text: "Exhibitions and Events",
	subItems: [
		{
			url: "/exhibitions",
			text: "Exhibitions"
		},
		{
			url: "/events",
			text: "Events"
		},
		{
			url: "/tours",
			text: "Free Tours"
		},
		{
			url: "/events?type=performances%2CperformanceArt%2Cmusic%2Cdance",
			text: "Performances"
		}
	]
}

const art = {
	text: "Art",
	subItems: [
		{
			url: "/art/collection",
			text: "The Met Collection"
		},
		{
			url: "/about-the-met/collection-areas",
			text: "Curatorial Areas"
		},
		{
			url: "/about-the-met/conservation-and-scientific-research",
			text: "Conservation and Scientific Research"
		}
	]
}

const learn = {
	text: "Learn with Us",
	subItems: [
		{
			url: "/learn/learning-resources",
			text: "Learning Resources"
		},
		{
			url: "/art/metpublications",
			text: "Publications"
		},
		{
			url: "/toah/",
			text: "Timeline of Art History"
		},
		{
			url: "/learn/workshops-and-activities",
			text: "Workshops and Activities"
		},
		{
			url: "/perspectives",
			text: "Articles, Videos, and Podcasts"
		}
	]
}

const research = {
	text: "Research",
	subItems: [
		{
			url: "/art/libraries-and-research-centers",
			text: "Libraries and Research Centers"
		},
	]
}

const shop = {
	text: "Shop",
	url: "https://store.metmuseum.org/?utm_source=mainmuseum&utm_medium=metmuseum.org&utm_campaign=topnav-static",
	external: true
}

const navData = [visit, exhibitions, art, learn, research, shop];

export default navData;
