"use client";

import styles from "./skip-to-main-content.module.scss";
const handleJumpToContent = () => {
  const mainContent = document.querySelector("header.masthead + *");
  mainContent.setAttribute('tabindex', '-1');
  mainContent.focus();
  mainContent.scrollIntoView();
};
const SkipToMainContent = () => {
  return <button className={`${styles.skipToMainContent} button secondary`} onClick={handleJumpToContent} id="skip-to-main-content-button" data-sentry-component="SkipToMainContent" data-sentry-source-file="index.jsx">
		Skip to main content
	</button>;
};
export default SkipToMainContent;