'use client';

import { emailSignupApiCall } from "components/homepage/email-signup";
import { useState } from "react";
import MetLogo from "assets/icons/the-met-logo.svg";
import YoutubeSVG from "assets/icons/social/youtube.svg";
import XSVG from "assets/icons/social/x.svg";
import InstagramSVG from "assets/icons/social/instagram.svg";
import PintrestSVG from "assets/icons/social/pintrest.svg";
import FacebookSVG from "assets/icons/social/facebook.svg";
import Link from "next/link";
import safeDataLayerPush from "lib/safeDataLayerPush";
import { CURRENT_YEAR } from "lib/constants.mjs";
import Button from "components/controls/buttons";
import styles from "./global-footer.module.scss";
import {footerLinks} from "./footer-data";

const GlobalFooter = () => {
	const [email, setEmail] = useState('');
	const [showSuccess, setShowSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isValid, setIsValid] = useState(true);
	const [isEmailDirty, setIsEmailDirty] = useState(false);

	const pageInfo = "web_footer";

	const submit = async evt => {
		evt.preventDefault();
		//Hide Previous Error State
		if (isValid && email != "") {
			setShowError(false);
			setShowSuccess(false);
			const res = await emailSignupApiCall(email, pageInfo);
			if (res.success) {
				setShowSuccess(true);
				setSuccessMessage(res.message);
				safeDataLayerPush({
					"Category": "Email",
					"Action": "Sign up",
					"event": "FooterNewsletterFormSubmitted"
				})
			}
			else {
				setShowError(true);
				setErrorMessage(res.message)
			}
		}
	};

	const checkValidity = event => {
		const emailInput = event.target;
		setIsValid(emailInput.validity.valid);
		setIsEmailDirty(true);
	};

	const handleMenuAnalytics = (e) => {
		safeDataLayerPush({
			event: "menu_click",
			menu_category_1: e.target?.innerText,
			menu_item_location: "footer"
		})
	}

	return (
		<footer className={styles.footer}>
			<section className={styles.locations}>
				<div className={styles.logoWrapper}>
					<Link
						title="Homepage"
						href="/" className={styles.logoLink}>
						<MetLogo style={{height: 40, width: 40}}/>
					</Link>
				</div>
				<ul className={styles.locationItems}>
					<li>
						<div>
							<div className={styles.museumAddressName}>
								<Link
									className={styles.link}
									href="/plan-your-visit/met-fifth-avenue"
									onClick={handleMenuAnalytics}>
									<span className={styles.locationTitle}>The Met Fifth Avenue</span>
								</Link>
							</div>
							<address>
								<div>
									<div>1000 Fifth Avenue</div>
									<div>New York, NY 10028</div>
									<div>Phone: 212-535-7710</div>
								</div>
							</address>
						</div>
					</li>
					<li>
						<div>
							<div className={styles.museumAddressName}>
								<Link
									className={styles.link}
									href="/plan-your-visit/met-cloisters"
									onClick={handleMenuAnalytics}>
									<span className={styles.locationTitle}>The Met Cloisters</span>
								</Link>
							</div>
							<address>
								<div>
									<div>99 Margaret Corbin Drive</div>
									<div>Fort Tryon Park</div>
									<div>New York, NY 10040</div>
									<div>Phone: 212-923-3700</div>
								</div>
							</address>
						</div>
					</li>
				</ul>
			</section>
			<section className={styles.linkContainer}>
				{footerLinks.map((footerSection, index) => {
					return (
						// eslint-disable-next-line react/no-array-index-key
						<div key={`footer-section-${index}`} className={styles.links}>
							<ul className={styles.navItems}>
								{footerSection.map(footerItem => {
									return <li key={`${footerItem.path}+${footerItem.title}`} className={styles.navItem}>
										<Link
											prefetch={false}
											href={footerItem.path}
											onClick={handleMenuAnalytics}
											className={styles.link}
										>{footerItem.title}</Link>
									</li>
								})}
							</ul>
						</div>
					)
				})}
			</section>
			<section className={`${styles.links} ${styles.social}`}>
				<span className={styles.title}>Follow us</span>
				<ul className={styles.socialItems}>
					{
						[
							{
								title: "external: facebook.com/metmuseum",
								url: "https://www.facebook.com/metmuseum",
								icon: <FacebookSVG className={styles.socialItemIcon}/>,
								siteName: "Facebook",
							},
							{
								title: "external: twitter.com/metmuseum",
								url: "https://x.com/metmuseum",
								icon: <XSVG className={styles.socialItemIcon}/>,
								siteName: "X",
							},
							{
								title: "external: pinterest.com/metmuseum/",
								url: "https://pinterest.com/metmuseum/",
								icon: <PintrestSVG className={styles.socialItemIcon}/>,
								siteName: "Pinterest",
							},
							{
								title: "external: instagram.com/metmuseum",
								url: "https://www.instagram.com/metmuseum/",
								icon: <InstagramSVG className={styles.socialItemIcon}/>,
								siteName: "Instagram",
							},
							{
								title: "external: youtube.com/user/metmuseum",
								url: "https://www.youtube.com/user/metmuseum",
								icon: <YoutubeSVG className={`${styles.iconYoutube} ${styles.socialItemIcon}`}/>,
								siteName: "YouTube",
							},
						].map(socialLink => {
							return (
								<li key={socialLink.siteName} className={styles.socialItem}>
									<Link
										prefetch={false}
										title={socialLink.title}
										href={socialLink.url}
										target="_blank"
										className={styles.socialLink}
										onClick={() => {
											safeDataLayerPush({
												event: "menu_click",
												menu_category_1: "Social",
												menu_category_2: socialLink.siteName,
												menu_item_location: "footer"
											})
										}}
										rel="noreferrer">
										{socialLink.icon}
									</Link>
								</li>
							)
						})
					}
				</ul>
			</section>
			<section className={styles.newsletter}>
				<form
					name="footerNewsletter"
					className={`${styles.newsletterSignup} ${styles.formFieldWrapper}`}>
					<div className={styles.globalFooterNewsletterWrapper}>
						<label className={styles.newsletterLabel} htmlFor="newsletter-email">
							Join our newsletter
						</label>
						{!showSuccess ? (<div className={styles.newsletterInputs}>
							<input
								className={styles.newsletterInput}
								type="email" autoComplete="email" 
								id="newsletter-email"
								placeholder="Enter your email" 
								value={email}
								onChange={e => { setEmail(e.target.value); }}
								onBlur={checkValidity}
								required="" />
							<Button
								appearance="secondary"
								type="submit"
								onClick={submit}>
								Sign Up
							</Button>
						</div>) : null }
						{!isValid && isEmailDirty ? (<div className={`${styles.formMessage} ${styles.error}`}>
							Please enter a valid email address.
						</div>) : null}
						{ showError ? (<div className={`${styles.formMessage} ${styles.error}`}>{errorMessage}</div>)
							: null }
						{ showSuccess ? (<p className={`${styles.formMessage} ${styles.success}`}>{successMessage}</p>)
							: null }
					</div>
				</form>
			</section>
			<section className={styles.info}>
				<ul className={styles.infoItems}>
					{[
						{ path: "/information/site-index", title: "Site Index" },
						{ path: "/information/terms-and-conditions", title: "Terms and Conditions" },
						{ path: "/information/privacy-policy", title: "Privacy Policy" },
						{ path: "/about-the-met/contact", title: "Contact Information" }
					].map(infoItem => {
						return <li key={infoItem.title} className={styles.infoItem}>
							<Link
								prefetch={false}
								href={infoItem.path}
								onClick={handleMenuAnalytics}
								className={styles.link}>{infoItem.title}
							</Link>
						</li>
					})}
				</ul>
				<div className={styles.copyrightInfo}>© 2000&ndash;{CURRENT_YEAR} The Metropolitan Museum of Art. All rights reserved.</div>
			</section>
		</footer>)
}

export default GlobalFooter;
