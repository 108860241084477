export const footerLinks = [
	[
		{ path: "/about-the-met", title: "About The Met" },
		{ path: "/about-the-met/history", title: "Mission and History" },
		{ path: "/about-the-met/collection-areas", title: "Collection Areas" },
		{ path: "/about-the-met/conservation-and-scientific-research", title: "Conservation Departments" },
		{ path: "/learn/accessibility", title: "Accessibility" },
		{ path: "/press", title: "Press" },
	],
	[
		{ path: "/join-and-give/support", title: "Support" },
		{ path: "https://engage.metmuseum.org/members/membership/?promocode=56373", title: "Membership" },
		{ path: "/join-and-give/host-an-event", title: "Host an Event" },
		{ path: "/join-and-give/travel-with-the-met", title: "Travel with The Met" },
		{ path: "/join-and-give/corporate-support", title: "Corporate Support" },
	],
	[
		{ path: "https://metmuseum.wd5.myworkdayjobs.com/en-US/metmuseumcareers", title: "Career Opportunities" },
		{ path: "/join-and-give/volunteer", title: "Volunteers" },
		{ path: "/about-the-met/fellowships", title: "Fellowships" },
		{ path: "/about-the-met/internships", title: "Internships" },
	],
];