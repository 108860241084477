import SearchIcon from "assets/icons/search.svg";
import PropTypes from "prop-types";
export const SearchItem = ({
  handleClick
}) => {
  return <li className="nav-main__primary-item nav-main__search-button" data-sentry-component="SearchItem" data-sentry-source-file="index.jsx">
			<a href="#" onClick={handleClick} role="button" title="Search Button" className="masthead__search">
				<SearchIcon className="icon" data-sentry-element="SearchIcon" data-sentry-source-file="index.jsx" />
				<span className="masthead__search-label">Search</span>
			</a>
		</li>;
};
SearchItem.propTypes = {
  handleClick: PropTypes.func.isRequired
};
export default SearchItem;