'use client';

import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePathname } from 'next/navigation' //TODO make this close on page change
import SearchIcon from "assets/icons/search.svg";
import HamburgerIcon from "assets/icons/hamburger.svg";
import CloseIcon from "assets/icons/close-x.svg";
import MetLogo from "assets/icons/the-met-logo.svg";
import navContent from "components/global/global-navigation/navigation-data";
import NavItem from "components/global/global-navigation/nav-item"
import SearchItem from "components/global/global-navigation/search-item"
import SearchSection from "components/global/global-navigation/search-section"
import BackgroundScrim from "./background-scrim";

const GlobalNavigation = () => {
	const [openNavItem, setOpenNavItem] = useState(null);
	const [showSearch, setShowSearch] = useState(false);
	const [showSubNav, setShowSubNav] = useState(false);
	const pathname = usePathname();
	const navRef = useRef();

	const handleHoverOff = () => {
		navRef?.current?.activeElement?.blur();
	}

	const handleSearchClick = (e) => {//Toggle search section
		e.preventDefault();
		setShowSubNav(false);
		setShowSearch(!showSearch);
	}

	const handleHamburgerClick = (e) => { //Toggle seeing links on mobile.
		e.preventDefault();
		setShowSubNav(!showSubNav);
		setShowSearch(false);
	}

	const handleNavItemClick = (e) => { //Toggle Submenu.
		e.preventDefault();
		const navSectionID = e.target.dataset.navId;
		openNavItem === navSectionID ? setOpenNavItem(null) : setOpenNavItem(navSectionID);
	};

	const handleOutsideEvent = useCallback((e) => { //Clicking off the nav closes sub-menus.
		if (!navRef?.current?.contains(e.target)) {
			document.removeEventListener("click",  handleOutsideEvent);
			document.removeEventListener("focusin",  handleOutsideEvent);

			setShowSubNav(false);
			setShowSearch(false);
		}
	}, []);

	useEffect(() => { //On page change reset nav.
		setShowSearch(false);
		setShowSubNav(false);
		setOpenNavItem(null);
	}, [pathname]);

	useEffect(() => { //If One of the submenus opens. Rebind the close event handler.
		document.addEventListener("click", handleOutsideEvent);
		document.addEventListener("focusin", handleOutsideEvent);
	},[showSearch, showSubNav, handleOutsideEvent]);
	
	return (
		<>
			<header className="masthead" onMouseLeave={handleHoverOff} ref={navRef}>
				<div className="masthead__wrap">

					<Link
						title="Homepage"
						href="/"
						className="masthead__logo-link">
						<span className="masthead__logo">
							<MetLogo style={{height: 40, width: 40}}/>
						</span>
					</Link>

					<div className="masthead__content">
						<div className="masthead__top-bar">

							<div className="masthead__eyebrow-links">
								<Link prefetch={false} className="masthead__buy-tickets"
									href="https://engage.metmuseum.org/admission/?promocode=55916">tickets</Link>
								<Link prefetch={false} className="masthead__membership"
									href="https://engage.metmuseum.org/members/membership/?promocode=56373">Member</Link>
								<span className="masthead__donation"> | <Link
									prefetch={false} href="https://engage.metmuseum.org/donate?promocode=56351">Make a donation</Link>
								</span>
							</div>

							<div className="masthead__top-right">

								<button
									title="Search Button"
									role="button"
									href="#"
									onClick={handleSearchClick}
									className="masthead__search masthead__search--small masthead-button">
									<SearchIcon
										className="icon"
										style={{transform: "rotate(270deg)"}}
									/>
									<span className="masthead__search-label">Search</span>
								</button>
							
								<a
									href="#"
									role="button"
									title="Toggle Menu"
									onClick={handleHamburgerClick}
									className={`masthead__hamburger masthead-button ${!showSubNav && "show-open"}`}>
									<HamburgerIcon className="icon masthead-icon--hamburger"/>
									<CloseIcon className="icon masthead-icon--close lil-nas-x"/>
								</a>
							</div>
						</div>

						<div className={`masthead__bottom-bar ${showSubNav && "is-open"}`}>
							<nav className="nav-main">
								<ul className="nav-main__primary">
									{navContent.map(navItem  => (
										<NavItem
											isActive={navItem.text === openNavItem}
											handleClick={handleNavItemClick}
											key={navItem.text}
											{...navItem}
										/>
									))}
									{showSearch && <SearchSection toggleVisible={setShowSearch}/>}
									<SearchItem handleClick={handleSearchClick}/>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</header>
			{showSearch && <BackgroundScrim/>}
		</>
	)
}

export default GlobalNavigation;
