"use client";

import { ReactNode, useState } from "react";
import Tabs from "components/controls/tabs";
import styles from "./tab-drawer.module.scss";
const TabDrawers = ({
  tabs,
  inputGroupName,
  screenreaderLegendText,
  orientation = "horizontal",
  hasDivider = true,
  showTabs = true,
  tabBodyWrapperClass
}: Props) => {
  const [activeTab, setActiveTab] = useState(tabs?.[0]);
  if (!tabs?.length) {
    return null;
  }
  const handleClick = e => {
    const tabValue = e.currentTarget.value;
    const newActiveTab = tabs.find(tab => tab.name === tabValue);
    setActiveTab(newActiveTab);
  };

  // Apply classes based on orientation prop
  const orientationClass = orientation === "vertical" ? styles.vertical : styles.horizontal;
  const bodyWrapperClass = tabBodyWrapperClass ? tabBodyWrapperClass : styles.bodyWrapper;
  return <div className={`${styles.wrapper} ${orientationClass}`} data-sentry-component="TabDrawers" data-sentry-source-file="index.tsx">
			{showTabs && <Tabs smallTabs={true} tabNames={tabs.map(tab => tab.name)} handleTabChange={handleClick} activeTab={activeTab.name} inputGroupName={inputGroupName} screenreaderLegendText={screenreaderLegendText} orientation={orientation} />}
			<div className={`${bodyWrapperClass} ${hasDivider && showTabs ? styles.divider : null}`} key={activeTab.name}>
				{activeTab.body}
			</div>
		</div>;
};
type Props = {
  inputGroupName?: string;
  screenreaderLegendText: string;
  tabs: Array<TabProps>;
  hasDivider?: boolean;
  showTabs?: boolean;
  orientation?: "horizontal" | "vertical";
  tabBodyWrapperClass?: string;
};
type TabProps = {
  name: string;
  body: ReactNode;
};
export default TabDrawers;